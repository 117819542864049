import React from "react";

import { FaChevronUp } from "react-icons/fa";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Piktogram from "../../images/favicon.inline.svg";

import * as css from "./footer.module.scss";

const Footer = () => (
  <footer id="footer" className={css.footer}>
    <div className={css.wrapper}>
      {/* <p>
        * Předplatné{" "}
        <a href="https://eshop.echomedia.cz/p/UAGhb/echo-komplet--voucher-na-akcii-echa">
          ECHO KOMPLET
        </a>{" "}
        obsahuje všechny periodické produkty v digitální podobě i tištěný
        TÝDENÍK ECHO
      </p> */}
      <Piktogram className={css.piktogram}></Piktogram>
      <h3 className={css.center}>Nadace Svoboda slova a myšlení</h3>
      <p className={css.center}>
        <a href="mailto:info@nadacesvobodaslova.cz">
          info@nadacesvobodaslova.cz
        </a>
        <br />
        Opletalova 1284/37, Nové Město, 110 00 Praha 1 <br />
        IČO: 081 19 953, zapsaná v nadačním rejstříku vedeném Městským soudem v
        Praze, spisová značka N 1703
      </p>
      {/* Copyright {new Date().getFullYear()} &copy; ECHO MEDIA, A.S. &copy; ČTK.
      PUBLIKOVÁNÍ NEBO ŠÍŘENÍ OBSAHU JE ZAKÁZÁNO BEZ PŘEDCHOZÍHO
      SOUHLASU.PROVOZOVATELEM SERVERU JE ECHO MEDIA, A.S., SE SÍDLEM
      MALOSTRANSKÉ NÁMĚSTÍ 203/14, MALÁ STRANA, 118 00 PRAHA, ČESKÁ REPUBLIKA •
      IČ: 02581574 ZAPSANÁ V OBCHODNÍM REJSTŘÍKU VEDENÉM MĚSTSKÝM SOUDEM V PRAZE
      POD SPISOVOU ZNAČKOU B 19589. */}
    </div>
    <AnchorLink className={css.backToTop} href="#top">
      <FaChevronUp />
    </AnchorLink>
    {/*
     * * purgeCss prevent for: svg i
     * ? ocividne staci 1 pro vsechny vsykyty
     */}
  </footer>
);

export default Footer;
