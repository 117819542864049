/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
// import CookieConsent from "react-cookie-consent";

// components
// import Header from "../Header/header";
import Footer from "../Footer/footer";

// css
import "../../scss/reset.scss";
import * as css from "./layout.module.scss";
// import '../../scss/default.scss';

const Layout = ({
  children,
  description = "",
  lang = "",
  meta = {},
  title = "",
  // ogImage = "",
  // location = {}
}) => {
  const { site, seoImage } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          lang
          siteUrl
          googleSiteVerification
        }
      }
    }
  `);
  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  const metaLang = lang || site.siteMetadata.lang;
  const gsv = site.siteMetadata.googleSiteVerification;
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: metaLang,
        }}
        lang={metaLang}
        charSet="UTF-8"
        title={title}
        defaultTitle={site.siteMetadata.title}
        titleTemplate={`%s | ${site.siteMetadata.title}}`}
        meta={[
          {
            name: "viewport",
            content: "width=device-width, initial-scale=1",
          },
          {
            name: "robots",
            content: "index,follow",
          },
          {
            name: "googlebot",
            content: "index,follow",
          },
          {
            name: "description",
            content: metaDescription,
          },
          {
            property: "og:title",
            content: metaTitle,
          },
          {
            property: "og:description",
            content: metaDescription,
          },
          {
            property: "og:type",
            content: "website",
          },
          {
            name: "twitter:card",
            content: "summary",
          },
          {
            name: "twitter:creator",
            content: site.siteMetadata.author,
          },
          {
            name: "twitter:title",
            content: metaTitle,
          },
          {
            name: "twitter:description",
            content: metaDescription,
          },
          { 
						name: "google-site-verification", 
						content: gsv 
					},
        ].concat(meta)}
      ></Helmet>
      <div id="top" className={css.body}>
        {/* <Header location={location} /> */}
        <main role="main">{children}</main>
        <Footer />
        {/* <CookieConsent buttonText="Rozumím">
          <div>
            Tento web využívá cookies pro zajištění funkčnosti webu a anonymní
            analýzu návštěvnosti přes GA.
          </div>
        </CookieConsent> */}
      </div>
    </>
  );
};

Layout.defaultProps = {
  lang: "cs-CZ",
  meta: [],
  description: "",
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};
export default Layout;
